@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');
html {
    @apply scroll-smooth scroll-pt-9
}
body {
    font-family: "Raleway", sans-serif;
}
section:not(#header-navigation) {
    @apply px-6
}

body h1,h2,h3,h4,h5{
    font-family: "Open Sans", sans-serif;
}
h3{
    @apply text-xl sm:text-2xl lg:text-3xl
}
h2{
    @apply text-2xl md:text-3xl lg:text-4xl
}
.flip-horizontal {
    --transform-scale-x: -1;
}