.technologies-item{
    @apply bg-white max-w-fit px-3 py-1 cursor-default rounded-lg text-lg sm:text-xl lg:text-2xl hover:scale-105;
}
.technologies-item span svg{
    @apply transition-transform duration-1000;
}
.technologies-item:hover span svg{
    @apply rotate-180 transition-transform duration-700;
}
.competences-item{
    @apply bg-white max-w-fit px-3 py-1 cursor-default rounded-lg text-[16px] hover:scale-105;
}